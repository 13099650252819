@use "sass:math";

// Media query variables.
$mobile:               "(min-width: #{$screen-xs-min})";
$tablet:              "(min-width: #{$screen-sm-min})";
$normal:              "(min-width: #{$screen-md-min})";
$wide:                "(min-width: #{$screen-lg-min})";
$grid-breakpoint:     "(min-width: #{$grid-float-breakpoint})";

$to-mobile:               "(max-width: #{$screen-xs-max})";
$to-tablet:              "(max-width: #{$screen-sm-max})";
$to-normal:              "(max-width: #{$screen-md-max})";

// Mlcet: More font sizes
$font-size-larger:        ceil(($font-size-base * 1.45)) !default; // ~20px
$font-size-smaller:       ceil(($font-size-base * .72)) !default; // ~10px

@media #{$to-mobile} {
  h1 { font-size: $font-size-h2; }
  h2 { font-size: $font-size-h3; }
  h3 { font-size: $font-size-h4; }
  h4 { font-size: $font-size-h5; }
}

// Mlcet: rotate glyphicons
.icon-flipped {
  transform: scaleX(-1);
}

// Drop shadows.
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;
          box-shadow: $shadow;
}

html {
  &.overlay-open .navbar-fixed-top {
    z-index: 400;
  }
  &.js {
    // Collapsible fieldsets.
    fieldset.collapsed {
      border-left-width: 1px;
      border-right-width: 1px;
      height: auto;
    }
    // Throbbers.
    input.form-autocomplete {
      background-image: none;
    }
    // Autocomplete.
    input.form-autocomplete {
      background-image: none;
    }
    // Autocomplete (fallback throbber, no icon).
    .autocomplete-throbber {
      background-position: 100% 2px;
      background-repeat: no-repeat;
      display: inline-block;
      height: 15px;
      margin: 2px 0 0 2px;
      width: 15px;
    }
    .autocomplete-throbber.throbbing {
      background-position: 100% -18px;
    }
  }
}
body {
  // Fix horizontal scrolling on iOS devices.
  // https://www.drupal.org/node/1870076
  position: relative;
  &.admin-expanded.admin-vertical.admin-nw .navbar,
  &.admin-expanded.admin-vertical.admin-sw .navbar {
    margin-left: 260px;
  }
  // Add padding to body if various toolbar or navbars are fixed on top.
  &.navbar-is-fixed-top {
    padding-top: 64px;
  }
  &.navbar-is-fixed-bottom {
    padding-bottom: 64px !important;
  }
  &.toolbar {
    padding-top: 30px !important;
    .navbar-fixed-top {
      top: 30px;
    }
    &.navbar-is-fixed-top {
      padding-top: 94px !important;
    }
  }
  &.toolbar-drawer {
    padding-top: 64px !important;
    .navbar-fixed-top {
      top: 64px;
    }
    &.navbar-is-fixed-top {
      padding-top: 128px !important;
    }
  }
  // Admin_menu toolbar.
  &.admin-menu {
    .navbar-fixed-top {
      top: 29px;
    }
    &.navbar-is-fixed-top {
      padding-top: 93px !important;
    }
  }
  div#admin-toolbar {
    z-index: 1600;
  }
  // Override box-shadow styles on all "admin" menus.
  #toolbar, #admin-menu, #admin-toolbar {
    @include box-shadow(none);
  }
  // Override #admin-menu style.
  #admin-menu {
    margin: 0;
    padding: 0;
    position: fixed;
    z-index: 1600;
    .dropdown li {
      line-height: normal;
    }
  }
}

// Default navbar.
.navbar {
  &.container {
    @media #{$tablet} {
      max-width: (($container-sm - $grid-gutter-width));
    }
    @media #{$normal} {
      max-width: (($container-md - $grid-gutter-width));
    }
    @media #{$wide} {
      max-width: (($container-lg - $grid-gutter-width));
    }
  }
  &.container,
  &.container-fluid {
    margin-top: 20px;
  }
  &.container > .container,
  &.container-fluid > .container-fluid {
    margin: 0;
    padding: 0;
    width: auto;
  }
}

// Adjust z-index of core components.
#overlay-container,
.overlay-modal-background,
.overlay-element {
  z-index: 1500;
}
#toolbar {
  z-index: 1600;
}
// Adjust z-index of bootstrap modals
.modal {
  z-index: 1620;
}
.modal-dialog {
  z-index: 1630;
}
.ctools-modal-dialog .modal-body {
  width: 100% !important;
  overflow: auto;
}
.modal-backdrop {
  z-index: 1610;
}
.footer {
  margin-top: 45px;
  padding-top: 35px;
  padding-bottom: 36px;
  border-top: 1px solid #E5E5E5;
}

// Element invisible fix
.element-invisible {
  margin: 0;
  padding: 0;
  width: 1px;
}

// Mlcet: Node and comments links
.links > li {
  // Increase visual distance between links
  margin-left: 8px;
}

// Mlcet: Glyphicons alignment
.glyphicon {
  vertical-align: text-top;
}

// Mlcet: Logo
.navbar .logo {
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  @media #{$to-mobile} {
    padding-left: 0;  // In small screens, padding was too much, this makes the burger and logo fit
  }
  @media #{$grid-breakpoint} {
    margin-right: 0;
    padding-left: 0;
  }
}

// Mlcet: Navbar menu links reduced horizontal padding in tablet resolution
@media #{$tablet} and #{$to-tablet} {
  .navbar-nav > li > a {
    padding-left: 6px;
  }
}

// Navigation.
ul.secondary {
  float: left;
  @media #{$tablet} {
    float: right;
  }
}

// Page header.
.page-header {
  margin-top: 0;
  // Mlcet: Reduce distance between title and local action buttons
  padding-bottom: 0;
  // Account for optical effect on alignment of text
  margin-left: 1px;
}

// Sidebar blocks.
.region-sidebar-first,
.region-sidebar-second {
  .block:first-child h2.block-title {
    margin-top: 0;
  }
}

// Paragraphs.
p:last-child {
  margin-bottom: 0;
}

// Help region.
.region-help {
  > .glyphicon {
    font-size: $font-size-large;
    float: left;
    margin: -0.05em 0.5em 0 0;
  }
  .block {
    overflow: hidden;
  }
}

// Mlcet: Search form
.navbar {
  form#search-block-form {
    margin: 0;
  }
  #block-search-form {
    float: none;
    .input-group-btn {
      width: auto;
    }
    @media #{$tablet} and #{$to-tablet} {
      input {
        display: none;
      }
      .btn {
        // btn-sm
        @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
        margin-top: 14px;
        margin-left: 6px;
        font-weight: bold;
      }
    }
    @media #{$normal} {
      margin-top: 7px;
      margin-right: 10px;
      display: inline-block;
      input {
        width: 8em;
        float: right;
      }
    }
    @media #{$wide} {
      input {
        width: 100%;
      }
    }
  }
  .navbar-search .control-group {
    margin-bottom:0px;
  }
}

// Navbar blocks

// Mlcet: Float the block container in order to keep order and allow vertical centering
.navbar .region-navigation {
  @media #{$normal} {
    float: right;
  }
}

// Mlcet: Make all blocks inline to avoid the normal flow
.navbar .block {
  display: inline-block;
  vertical-align: middle;
}

// Mlcet: Reduce header right margin
.navbar {
  padding-right: 0;
}

// Mlcet: Theme user image on nav user block
#block-mlcet-nav-user {
  img {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-right: 2px;
    background-color: $navbar-inverse-color;
    border: 1px solid $gray;
    border-radius: 50%;
  }
  .dropdown-menu {
    right: 0;
    left: auto; // reset to default value
  }
  .username {
    padding: 3px 20px;
  }
}

// Mlcet: Theme login/register buttons on nav bar
#block-mlcet-nav-login-register {
  @media #{$to-tablet} {
    .navbar-btn:nth-child(2) {
      margin-left: 10px;
    }
  }
  @media #{$normal} {
    .navbar-btn {
      margin-left: 10px;
    }
  }
   @media #{$tablet} and #{$to-tablet} {
    .btn {
      // btn-sm
      @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
      margin-top: 14px;
      font-weight: bold;
    }
  }
}

// Action Links
ul.action-links {
  margin: $padding-base-horizontal 0;
  padding: 0;
  li {
    display: inline;
    margin: 0;
    padding: 0 $padding-base-vertical 0 0;
  }
  .glyphicon {
    padding-right: 0.5em;
  }
}

// Form elements.
input, textarea, select, .uneditable-input {
  max-width: 100%;
  width: auto;
}
input.error {
  color: $state-danger-text;
  border-color: $state-danger-border;
}
fieldset legend.panel-heading {
  float: left;
  line-height: 1em;
  margin: 0;
}
fieldset .panel-body {
  clear: both;
}
fieldset .panel-heading a.panel-title {
  color: inherit;
  display: block;
  margin: -10px -15px;
  padding: 10px 15px;
  &:hover {
    text-decoration: none;
  }
}
.form-group:last-child,
.panel:last-child {
  margin-bottom: 0;
}
.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0;
}
.form-actions{
  clear: both;
}
.managed-files.table {
  td:first-child {
    width: 100%;
  }
}
div.image-widget-data {
  float: none;
  overflow: hidden;
}
table.sticky-header {
  z-index: 1;
}
.resizable-textarea textarea {
  border-radius: $border-radius-base $border-radius-base 0 0;
}
.text-format-wrapper {
  // Use same value as .form-group.
  margin-bottom: 15px;
  > .form-type-textarea,
  .filter-wrapper {
    margin-bottom: 0;
  }
}
.filter-wrapper {
  border-radius: 0 0 $border-radius-base $border-radius-base;
  .panel-body {
    padding: 7px;
  }
  .form-type-select {
    min-width: 30%;
    .filter-list {
      width: 100%;
    }
  }
}
.filter-help {
  margin-top: 5px;
  text-align: center;
  @media #{$tablet} {
    float: right;
  }
  .glyphicon {
    margin: 0 5px 0 0;
    vertical-align: text-top;
  }
}
.radio, .checkbox {
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
//.help-block,
.control-group .help-inline {
  color: $gray-light;
  font-size: 12px;
  margin: 5px 0 10px;
  padding: 0;
}
.panel-heading {
  display: block;
}
a.tabledrag-handle .handle {
  height: auto;
  width: auto;
}

// Error containers.
.error {
  color: $state-danger-text;
}
div.error,
table tr.error {
  background-color: $state-danger-bg;
  color: $state-danger-text;
}
.form-group.error,
.form-group.has-error {
  background: none;
  label, .control-label {
    color: $state-danger-text;
    font-weight: 600;
  }
  input, textarea, select, .uneditable-input {
    color: $input-color;
  }
  .help-block, .help-inline {
    color: $text-muted;
  }
}

// Lists
.nav-tabs {
  margin-bottom: 10px;
}
ul li.collapsed,
ul li.expanded,
ul li.leaf {
  list-style: none;
  list-style-image: none;
}
.tabs--secondary {
  margin: 0 0 10px;
}

// Submitted
.submitted {
  // Mlcet: remove margin
  //margin-bottom: 1em;
  font-style: italic;
  font-weight: normal;
  color: #777;
}

// Password strength/match.
.form-type-password-confirm {
  position: relative;
  label {
    display: block;
    .label {
      float: right;
    }
  }
  .password-help {
    padding-left: 2em;
    @media (min-width: $screen-sm-min) {
      border-left: 1px solid $table-border-color;
      left: percentage((6/12));
      margin-left: ($grid-gutter-width / 2);
      position: absolute;
    }
    @media (min-width: $screen-md-min) {
      left: percentage((4/12));
    }
  }
  .progress {
    background: transparent;
    -moz-border-radius: 0 0 5px 5px;
    -webkit-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px;
    @include box-shadow(none);
    height: 4px;
    margin: -5px 0px 0;
  }
  .form-type-password {
    clear: left;
  }
  .form-control-feedback {
    right: 15px;
  }
  .help-block {
    clear: both;
  }
}

// Views AJAX pager.
ul.pagination li > a {
  &.progress-disabled {
    float: left;
  }
}

// Autocomplete and throbber
.form-autocomplete {
  .glyphicon {
    color: $gray-light;
    font-size: 120%;
    &.glyphicon-spin {
      color: $brand-primary;
    }
  }
  .input-group-addon {
    background-color: rgb(255, 255, 255);
  }
}

// AJAX "Progress bar".
.ajax-progress-bar {
  border-radius: 0 0 $border-radius-base $border-radius-base;
  border: 1px solid $input-group-addon-border-color;
  margin: -1px 0 0;
  padding: $padding-base-vertical $padding-base-horizontal;
  width: 100%;
  .progress {
    height: 8px;
    margin: 0;
  }
  .percentage,
  .message {
    color: $text-muted;
    font-size: $font-size-small;
    line-height: 1em;
    margin: 5px 0 0;
    padding: 0;
  }
}

.glyphicon-spin {
  display: inline-block;
  -moz-animation: spin 1s infinite linear;
  -o-animation: spin 1s infinite linear;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}
a .glyphicon-spin {
  display: inline-block;
  text-decoration: none;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

/**
 * Missing Bootstrap 2 tab styling.
 * @see http://stackoverflow.com/questions/18432577/stacked-tabs-in-bootstrap-3
 * @see http://bootply.com/74926
 */
.tabbable {
  margin-bottom: 20px;
}
.tabs-below, .tabs-left, .tabs-right {
  > .nav-tabs {
    border-bottom: 0;
    .summary {
      color: $nav-disabled-link-color;
      font-size: $font-size-small;
    }
  }
}
.tab-pane > .panel-heading {
  display: none;
}
.tab-content > .active {
  display: block;
}

// Below.
.tabs-below {
  > .nav-tabs {
    border-top: 1px solid $nav-tabs-border-color;
    > li {
      margin-top: -1px;
      margin-bottom: 0;
      > a {
        border-radius: 0 0 $border-radius-base $border-radius-base;
        &:hover,
        &:focus {
          border-top-color: $nav-tabs-border-color;
          border-bottom-color: transparent;
        }
      }
    }
    > .active {
      > a,
      > a:hover,
      > a:focus {
        border-color: transparent $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color;
      }
    }
  }
}

// Left and right tabs.
.tabs-left,
.tabs-right {
  > .nav-tabs {
    padding-bottom: 20px;
    width: 220px;
    > li {
      float: none;
      &:focus {
        outline: 0;
      }
      > a {
        margin-right: 0;
        margin-bottom: 3px;
        &:focus {
          outline: 0;
        }
      }
    }
  }
  > .tab-content {
    border-radius: 0 $border-radius-base $border-radius-base $border-radius-base;
    @include box-shadow(0 1px 1px rgba(0,0,0,.05));
    border: 1px solid $nav-tabs-border-color;
    overflow: hidden;
    padding: 10px 15px;
  }
}

// Left tabs.
.tabs-left {
  > .nav-tabs {
    float: left;
    margin-right: -1px;
    > li > a {
      border-radius: $border-radius-base 0 0 $border-radius-base;
      &:hover,
      &:focus {
        border-color: $nav-tabs-link-hover-border-color $nav-tabs-border-color $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color;
      }
    }
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      border-color: $nav-tabs-border-color transparent $nav-tabs-border-color $nav-tabs-border-color;
      @include box-shadow(-1px 1px 1px rgba(0,0,0,.05));
    }
  }
}

// Right tabs.
.tabs-right {
  > .nav-tabs {
    float: right;
    margin-left: -1px;
    > li > a {
      border-radius: 0 $border-radius-base $border-radius-base 0;
      &:hover,
      &:focus {
        border-color: $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-border-color;
        @include box-shadow(1px 1px 1px rgba(0,0,0,.05));
      }
    }
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color transparent;
    }
  }
}

// Checkbox cell fix.
th.checkbox,
td.checkbox,
th.radio,
td.radio {
  display: table-cell;
}

// Views UI fixes.
.views-display-settings {
  .label {
    font-size: 100%;
    color:#666666;
  }
  .footer {
    padding:0;
    margin:4px 0 0 0;
  }
}
.views-exposed-form .views-exposed-widget .btn {
  margin-top: 1.8em;
}

// Radio and checkbox in table fixes
table {
  .radio input[type="radio"],
  .checkbox input[type="checkbox"] {
    max-width: inherit;
  }
}

// Exposed filters
.form-horizontal .form-group label {
  position: relative;
  min-height: 1px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
  padding-left:  ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);
  text-align: right;

  @media (min-width: $screen-sm-min) {
    float: left;
    width: percentage((2 / $grid-columns));
  }
}

// Treat all links inside alert as .alert-link
.alert a {
  font-weight: $alert-link-font-weight;
}
.alert-success {
  a, a:hover, a:focus {
    color: darken($alert-success-text, 10%);
  }
}
.alert-info {
  a, a:hover, a:focus {
    color: darken($alert-info-text, 10%);
  }
}
.alert-warning {
  a, a:hover, a:focus {
    color: darken($alert-warning-text, 10%);
  }
}
.alert-danger {
  a, a:hover, a:focus {
    color: darken($alert-danger-text, 10%);
  }
}

// Override image module.
div.image-style-new,
div.image-style-new div {
  display: block;
}
div.image-style-new div.input-group {
  display: table;
}

td.module,
.table-striped>tbody>tr:nth-child(odd)>td.module,
.table>tbody>tr>td.module {
  background: $table-border-color;
  font-weight: 700;
}

// Book module.
.book-toc > .dropdown-menu {
  overflow: hidden;
  > .dropdown-header {
    white-space: nowrap;
  }
  > li:nth-child(1) > a {
    font-weight: bold;
  }
  .dropdown-menu {
    @include box-shadow(none);
    border: 0;
    display: block;
    font-size: $font-size-small;
    margin: 0;
    padding: 0;
    position: static;
    width: 100%;
    > li {
      padding-left: 20px;
      > a {
        margin-left: -40px;
        padding-left: 60px;
      }
    }
  }
}

// Features module.
#features-filter .form-item.form-type-checkbox {
  padding-left: 20px;
}
fieldset.features-export-component {
  font-size: $font-size-small;
  html.js #features-export-form &, & {
    margin: 0 0 ($line-height-computed / 2);
  }
  .panel-heading {
    padding: 5px 10px;
    a.panel-title {
      font-size: $font-size-small;
      font-weight: 500;
      margin: -5px -10px;
      padding: 5px 10px;
    }
  }
  .panel-body {
    padding: 0 10px;
  }
}
div.features-export-list {
  margin: -11px 0 10px;
  padding: 0 10px;
}

fieldset.features-export-component .component-select ,
div.features-export-list {
  .form-type-checkbox {
    line-height: 1em;
    margin: 5px 5px 5px 0 !important;
    min-height: 0;
    padding: 3px 3px 3px 25px!important;
    input[type=checkbox] {
      margin-top: 0;
    }
  }
}

// Navbar module.
body.navbar-is-fixed-top.navbar-administration.navbar-horizontal.navbar-tray-open .navbar-fixed-top {
  top: 79px;
}
body.navbar-is-fixed-top.navbar-administration .navbar-fixed-top {
  top: 39px;
}
/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) {
  body.navbar-is-fixed-top.navbar-administration.navbar-vertical.navbar-tray-open .navbar-fixed-top {
    left: 240px;
    left: 24rem;
  }
}
.navbar-administration #navbar-administration.navbar-oriented .navbar-bar{
  z-index: 1032;
}
.navbar-administration #navbar-administration .navbar-tray {
  z-index: 1031;
}
body.navbar-is-fixed-top.navbar-administration {
  padding-top: 103px!important;
}
body.navbar-is-fixed-top.navbar-administration.navbar-horizontal.navbar-tray-open {
  padding-top: 143px!important;
}
body.navbar-tray-open.navbar-vertical.navbar-fixed {
  margin-left: 240px;
  margin-left: 24rem;
}
#navbar-administration.navbar-oriented .navbar-tray-vertical {
  width: 24rem;
}

/**
 * Icon styles.
 */
a {
  &.icon-before .glyphicon,
  & .glyphicon.icon-before {
    margin-right: .25em;
  }
  &.icon-after .glyphicon,
  & .glyphicon.icon-after {
    margin-left: .25em;
  }
}

.btn {
  &.icon-before .glyphicon,
  & .glyphicon.icon-before {
    margin-left: -.25em;
    margin-right: .25em;
  }
  &.icon-after .glyphicon,
  & .glyphicon.icon-after {
    margin-left: .25em;
    margin-right: -.25em;
  }
}

// Mlcet: change color of text links inside badges to make it visible
table .badge {
  a, a:visited, a:hover, a:active {
    color: $component-active-color;
  }
}

// Mlcet: Journal node teaser
article.node-blog.node-teaser {
  margin: 10px 0;

  // Header and sub-header for journals in listings
  header.no-full-page {
    overflow: hidden; // avoid user image to float outside header
    background-color: $gray-lighter;
    border-radius: $panel-border-radius;
    padding: 15px;
    .by-author {
      font-size: $font-size-small;
    }
    .user-picture {
      float: left;
      margin-right: 1em;
    }
   }
  .sub-header-no-full-page {
    padding-left: 1px; // like header.no-full-page.padding
    margin-top: 10px;
    .date {
      font-weight: $blog-post-date-font-weight;
    }
    .comment-num, .view-num { margin-left: 15px; }
    @media #{$to-mobile} {
      font-size: $font-size-small;
    }
  }
  footer {
    // Avoid using float: right
    display: flex;
    justify-content: right;
    // Give more space around the "read more" link
    margin: 5px 0 20px 0;
  }
}

// Mlcet: Journal node full
@media #{$to-mobile} {
  .node-type-blog .pager {
    // Reduce font size of prev/next links in mobile devices
    font-size: $font-size-small;
  }
}

article.node-blog {
  // Header for full page journal
  header.full-page {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    @media #{$to-mobile} {
      font-size: $font-size-small;
    }
   .author {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .date {
      margin-left: 16px;
    }
    .user-picture {
      margin-right: 8px;
    }
  }

  // Common styles for all journal nodes
  .user-picture {
    img {
      width: 36px;
      min-width: 36px;
      height: 36px;
      min-height: 36px;
      border-radius: 50%;
      box-shadow: 2px 2px 2px gray;
      vertical-align: middle;
    }
  }
  h4 {
    margin: 0 0 5px 0;
  }
  .field-name-body {
    margin: 10px 10px 2px 10px;
  }
  .links  {
    // Great way to align the links to the right without using float
    display: flex;
    justify-content: flex-end;
    margin-top: 11px;
  }
 // Comments on full page journal nodes
  #comments {
    .indented {
      margin-left: 20px;
    }
    .alert {
      margin-top: 20px;
    }
  }
  #comments > .comment-form {
    margin-top: 10px;
    padding-top: 20px;
    border-top: 1px solid #ecf0f1;
  }
  .comment {
    overflow: hidden;
    // Add small separator line between comments
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid $gray-light;
    // End separator line
    .user-picture img {
      min-width: 32px;
      min-height: 32px;
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }
    .comment-header {
      display: flex;
      align-items: center;
      @media #{$to-mobile} {
        font-size: $font-size-small;
      }
    }
    .author {
      display: flex;
      align-items: center;
      margin-right: 1em;
    }
    .date {
      margin-right: 1em;
    }
    .new {
      margin-right: 1em;
    }
    .content {
      clear: both;
      padding: 10px;
      margin: 10px 0;
      border-left: 1px solid $gray-lighter;
    }
  }
}

// Mlcet: Blog listing
article.node-blog + .pager {
  font-size: $font-size-smaller;
  .pager-previous {
    margin-right: 5px;
  }
  .pager-next {
    margin-left: 5px;
  }
}

// Mlcet: Diario Rebeca
.view-diario-de-rebeca > .pager {
  font-size: $font-size-smaller;
  .pager-previous {
    margin-right: 5px;
  }
  .pager-next {
    margin-left: 5px;
  }
}

// Mlcet: Forum node
article.node-forum {
  header {
    .user-picture {
      margin-right: 1em;
      img {
        width: 36px;
        min-width: 36px;
        height: 36px;
        min-height: 36px;
        border-radius: 50%;
        box-shadow: 2px 2px 2px gray;
        @include center-block();
      }
    }
    .panel-body::before, .panel-body::after {
      content: none;
    }
    .panel-user-info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      @media #{$tablet} {
        flex-direction: column;
        align-items: flex-start;
      }
      justify-content: space-between;
      padding: 10px;
      font-size: $font-size-small;
    }
    .author {
      flex: 1.5;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .user-info {
      @media #{$tablet} {
        margin-top: 1em;
      }
    }
    @media #{$to-mobile} {
      .panel {
        margin-bottom: 22px;
      }
    }
  }
  footer {
    margin-top: 10px;
  }
  #comments {
    margin-top: 10px;
  }
  .comment-add .glyphicon {
    transform: scale(-1, 1);
  }
  ul.links {
    margin: 0;
    // Great way to align the links to the right without using float
    display: flex;
    justify-content: flex-end;
  }
  .row {
    margin-bottom: 20px;
  }
  .speech-bubble {
    background-color: $gray-lighter;
    border-radius: 0 20px 20px 20px;
    padding: 15px;
  }
}

// Mlcet: Forum listing (base)
.view-foro {
  .user-picture {
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      box-shadow: 2px 2px 2px gray;
    }
  }
  td:nth-child(1) {
    min-width: 40px;
    padding: 8px 4px;
  }
  td:nth-child(2) {
    width: 80%;
  }
  td:nth-child(3) {
    width: 0%;
  }
  td:nth-child(4) {
    width: 10%;
  }
  .label, .views-field-last-comment-timestamp {
    display: none;
  }
  h4 {
    margin: 0;
    a {
      color: $text-color;
    }
  }
  .views-field-title {
    font-size: $font-size-smaller;
    color: $gray;
    p {
      margin-top: 3px;
    }
    p:nth-last-child(2) { // hide author if there's any comment
      display: none;
    }
    .placeholder {
      font-style: normal;
    }
  }
  .views-field-term-node-tid {
    text-align: right;
  }
  .views-field-comment-count p:first-child {
    display: none;
  }
  .views-field-comment-count .badge {
    color: $body-bg;
    background-color: $gray-dark;
  }
  .pager {
    font-size: $font-size-smaller;
    .pager-previous {
      margin-right: 5px;
    }
    .pager-next {
      margin-left: 5px;
    }
  }
}

// Mlcet: Forum listing (tablets and more)
@media #{$tablet} {
.view-foro {
  .user-picture {
    float: right;
  }
  td:nth-child(2) {
    width: 80%;
  }
  td:nth-child(3) {
    width: 10%;
  }
  td:nth-child(4) {
    min-width: 64px;
    width: 64px;
    max-width: 64px;
  }
  h4 {
    margin: 0;
  }
  td a.username td span {
    color: $gray;
  }
  p {
    font-size: $font-size-small;
    color: $gray;
    margin: 0;
    padding: 0;
  }
  .views-field-last-comment-timestamp {
    display: table-cell;
  }
  .label {
    display: inline;
  }
  .views-field-title {
    font-size: $font-size-small;
    span:last-child {
      display: inline;
    }
  }
  .views-field-term-node-tid {
    text-align: right;
  }
  .views-field-comment-count p:first-child {
    display: block;
    color: $gray;
    background-color: transparent;
    .glyphicon {
      margin-right: 0.25em;
      vertical-align: text-bottom;
    }
  }
  .views-field-comment-count .badge {
    display: none;
  }
  .pager {
    font-size: $font-size-small;
  }
}
}

// Mlcet: Forum tag labels
.label-n-experiencias {
  @include label-variant(#596fb7);
}

.label-n-sobre-la-web {
  @include label-variant(#ffa600);
}

.label-n-preguntas {
  @include label-variant(#9C980C);
}

.label-n-métodos {
  @include label-variant(#006b9c);
}

.label-n-noticias {
  // use a blue-purple pastel color for the web
  @include label-variant(#ff7d4e)
}

.label-n-entretenimiento {
  @include label-variant(#ff6281);
}

.label-n-motivación {
  // use different color than those used in the other labels
  @include label-variant(#de61a8);
}

// Mlcet: User page
.page-user {
  .profile {
    margin: 0; // Avoid row class negative margins
    @media #{$to-mobile} {
      padding: 10px 0;
      h4 {
        font-size: $font-size-h4;
        margin-top: 20px;
      }
    }
    min-height: 160px; // In case profile contents are not high enough
    .user-info {
      display: flex;
      @media #{$to-mobile} {
        flex-direction: row-reverse;
      }
    }
    .user-picture {
      @media #{$to-mobile} {
        margin-left: 10px;
        flex-shrink: 0;
        img {
          width: 90px;
          height: 90px;
          border-radius: 45px;
          box-shadow: 2px 2px 2px gray;
        }
      }
      @media #{$tablet} {
        margin-right: 20px;
        img {
          width: 120px;
          height: 120px;
          border-radius: 60px;
          box-shadow: 2px 2px 2px gray;
        }
      }
    }
    h1 {
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }
  .block-views {
    width: 50%;
    float: left !important;
    padding: 19px; // Same than .well
  }
  // Only for the user profile blog teaser
  .block-sub-header {
    margin-top: 20px;
    color: $gray-light;
    .comment-num {
      margin-left: 15px;
    }
  }
  article.node-blog {
    footer {
      margin-top: 20px;
    }
  }
  .view-actividad {
    .views-row {
      margin-bottom: 10px;
    }
  }
  ul.action-links {
    margin: 22px 0;
  }
  .action-links li {
    display: block;
    margin-bottom: 15px;
  }
}

// Mlcet: User edit page
.page-user-edit-perfil {
  #edit-profile-fecha-sin-fumar {
    display: flex;
  }
}

.region-footer {
  .btn {
    margin: 20px;
  }
}

// Mlcet: Frontpage
.page-frontpage .main-container {
  // Main container aligned with logo (1x to keep it like it was, 2x for aligning it)
  @media #{$tablet} {
    padding-left: 3 * floor(math.div($grid-gutter-width, 2));
    padding-right: 3 * floor(math.div($grid-gutter-width, 2));
  }
  h1 {
    margin-top: 8px;
    margin-bottom: 10px;
  }
  .lead {
    color: $gray;
    font-weight: 400;
    font-size: $font-size-large;
    margin-bottom: 50px;
    @media #{$tablet} {
      margin-bottom: 80px;
    }
  }
  .block-leads {
    .lead-sentence {
      font-weight: bold;
      font-size: $font-size-base;
      margin-bottom: 5px;
    }
    .lead-desc {
      line-height: $line-height-large;
      @media #{$to-mobile} {
        margin-bottom: 2em;
      }
    }
    .glyphicon {
      display: block;
      font-size: $font-size-h1;
      margin-right: 15px;
      float: right !important;
    }
    .lead-questions .glyphicon {
      color: #2D9CDB;
    }
    .lead-sharing .glyphicon {
      color: #6FCF97;
    }
    .lead-generosity .glyphicon {
      color: #EB5757;
    }
  }

// Mlcet: Block stats
@media #{$to-tablet} {
  .block-stats {
    margin-top: 30px;
    @media #{$tablet} {
      margin-top: 90px;
    }
    .stats-point {
      text-align: center;
      font-size: $font-size-small;
      em {
        font-weight: bolder;
        font-style: normal;
      }
    }
  }
}

@media #{$normal} {
  .block-stats {
    margin-top: 90px;
    padding: 25px;
    .stats-point {
      font-size: $font-size-small;
      margin-left: 25%;
      em {
        font-size: $font-size-larger;
        font-weight: bolder;
        font-style: normal;
      }
    }
  }
}

  .block-rebeca {
    margin-top: 90px;
    line-height: $line-height-large;
    img {
      border-radius: 4px;
      box-shadow: 0 4px 4px 0 rgba(0,0,0,.25);
    }
    .img-container {
      padding-top: 22px;
    }
    .glyphicon {
      display: block;
      margin-left: 20px;
      float: left;
      font-size: $font-size-h2;
    }
    h3 {
      margin-bottom: $line-height-computed * 2;
    }
    blockquote {
      font-size: $font-size-base;
    }
    .btn {
      margin-top: 20px;
    }
    @media #{$to-normal} {
      .img-container {
        display: none;
      }
      margin: 0;
      padding: 0;
    }
  }
}

// Mlcet: Cookie banner
#sliding-popup {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 99999;
  background: #ECF0F1;
  text-align: center;
  .popup-content {
    margin: 0 auto;
    max-width: 80%;
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  #popup-text {
    max-width: 100% !important;
    color: #2C3E50 !important;
    float: none !important;
    margin: 5px auto;
  }
  #popup-buttons {
    display: none;
  }
}

// CKEditor

.cke_editable {
  margin: 1em;
}

// Scroll to top button

.scrollToTopBtn {
  background-color: #b9edde;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;

  font-size: 16px;
  line-height: 48px;
  width: 48px;
  position: fixed;
  bottom: 30px;
  right: 30px;

  @media #{$to-mobile} {
    bottom: 20px;
    right: 20px;
  }
  
  z-index: 100;
  opacity: 0;
  transform: translateY(100px);
  transition: all .5s ease
}

.showBtn {
  opacity: 1;
  transform: translateY(0)
}

// Progress bar

.progress-bar {
  height: 1rem;
  background: #b9edde;
  position: fixed;
  top: 0;
  left: 0;
}

// Like button
.glyphicon-like {
  color: $gray-dark;
}
.glyphicon-unlike {
  color: #ff6281;
}

// Pagination

.forum-pager .pagination:first-child {
  margin: 0;
}

.forum-pager > .pagination {
  display: inline-block;
  padding-left: 0;
  margin: $line-height-computed 0;
  border-radius: $border-radius-base;

  @media #{$to-mobile} {
  > li.normal {
      display: none;
    }
  }

  > li {
    display: inline; // Remove list-style and block-level defaults
    > a,
    > span {
      position: relative;
      float: left; // Collapse white-space
      padding: $padding-base-vertical $padding-base-horizontal;
      margin-left: -1px;
      line-height: $line-height-base;
      color: $link-color;
      text-decoration: none;
      background-color: transparent;
      border: 1px solid $pagination-border;

      &:hover,
      &:focus {
        z-index: 2;
        color: $pagination-hover-color;
        background-color: $pagination-bg;
        border-color: $pagination-hover-border;
      }
    }
    &:first-child {
      > a,
      > span {
        margin-left: 0;
        @include border-left-radius($border-radius-base);
      }
    }
    &:last-child {
      > a,
      > span {
        @include border-right-radius($border-radius-base);
      }
    }
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
      display: inline;
      @media #{$to-mobile} {
        display: none;
      }
      z-index: 3;
      color: $text-color;
      font-weight: bold;
      cursor: default;
      background-color: transparent;
      border-color: $pagination-active-border;
    }
  }

  > .active-condensed > span {
     &,
    &:hover,
    &:focus {
      display: inline;
      @media #{$tablet} {
        display: none;
      }
      z-index: 3;
      color: $text-color;
      cursor: default;
      background-color: transparent;
      border-color: $pagination-active-border;
    }
    .bold {
      font-weight: bold;
    }
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
      display: inline;
      @media #{$to-mobile} {
        display: none;
      }
      color: $text-color;
      cursor: $cursor-disabled;
      background-color: transparent;
      border-color: $pagination-disabled-border;
    }
  }
}

